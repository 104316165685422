import React from "react"
import Footer from "./footer"
import { useStaticQuery, Link, graphql } from "gatsby"
import { isAuthenticated, logout, getProfile } from "../utils/auth"

export default ({ children }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            github
            twitter
          }
        }
      }
    `
  )
  if (isAuthenticated()) {
    const user = getProfile()
  }

  return (
    <div className="container main">
      <header className="container">
        <Link to={`/`}>
          <h3>{data.site.siteMetadata.title}</h3>
        </Link>
        <ul className="container nav">
          <li>
            <Link to={`/account/`}>Account</Link>
          </li>
          <li>
            <Link to={`/about/`}>About</Link>
          </li>
          {isAuthenticated() ? (
            <li>
              <a
                href="#logout"
                onClick={e => {
                  logout()
                  e.preventDefault()
                }}
              >
                Log Out
              </a>
            </li>
          ) : (
            <li>
              <Link to={`/account/`}>Sign In</Link>
            </li>
          )}
        </ul>
      </header>
      {children}
      <div>{data.site.siteMetadata.title}</div>
      <Footer
        github={data.site.siteMetadata.github}
        twitter={data.site.siteMetadata.twitter}
      ></Footer>
    </div>
  )
}
