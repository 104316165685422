import React from "react"
import { Link } from "gatsby"

export default (props) => {
    const githubUser = props.github
    const twitterUser = props.twitter
  return (
    <footer className="container">
        <ul>
          <Link to='/posts/'><li>blog posts</li></Link>
          <li>reading list</li>
          <li>email</li>
        </ul>
        <ul>
          <a href={`https://github.com/${githubUser}`}><li>github</li></a>
          <a href={`https://twitter.com/${twitterUser}`}><li>twitter</li></a>
          <li>LI</li>
        </ul>
      </footer>
  )
}
